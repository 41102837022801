import revive_payload_client_ZFB0dlLub0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RmUQuhtwN6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UvuhxWbfmt from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_jSlil2QmfG from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0lxop6jY0f from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XP25nuHmjU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FVsItApgVh from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_EgEgV4Jny1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_@upstash+redis@1.34.4_eslint@9.10.0_ji_as3ehucxvpkbnejltjurnmrvie/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_DaCbS7aF3y from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.0_@types+node@22.13.2_jiti@2.4.2_sass@1.79.4_terser_4xhxadnbquanz53qan5hxe2cvi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_oRdNlzmhcj from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_Abqfy95pSS from "/vercel/path0/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.2.1_@tanstack+vue-query@5.56.2_vue@3.5.12_typescript@5.6.3___nu_7fioj4rbryrdybrfs4id5jfagy/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_YxRcO84sMh from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.22.5_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_5MfVKlP8dM from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.22.5_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import tooltip_KTCF4H9yoS from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.3.1_magicast@0.2.11_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._hobhujc2zqzx5k5pmh6y3jn4zq/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import _01_posthog_client_njXv1AyeXs from "/vercel/path0/apps/app/plugins/01.posthog.client.ts";
import _02_legacy_client_v1XKct5FiP from "/vercel/path0/apps/app/plugins/02.legacy.client.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/app/plugins/bugsnag.ts";
import gtm_client_OzmBuHLRIb from "/vercel/path0/apps/app/plugins/gtm.client.ts";
import intercom_client_vr7NPky0pK from "/vercel/path0/apps/app/plugins/intercom.client.ts";
export default [
  revive_payload_client_ZFB0dlLub0,
  unhead_RmUQuhtwN6,
  router_UvuhxWbfmt,
  payload_client_jSlil2QmfG,
  navigation_repaint_client_0lxop6jY0f,
  check_outdated_build_client_XP25nuHmjU,
  chunk_reload_client_FVsItApgVh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EgEgV4Jny1,
  plugin_DaCbS7aF3y,
  plugin_oRdNlzmhcj,
  plugin_Abqfy95pSS,
  plugin_ywJDomcNte,
  switch_locale_path_ssr_YxRcO84sMh,
  i18n_5MfVKlP8dM,
  tooltip_KTCF4H9yoS,
  _01_posthog_client_njXv1AyeXs,
  _02_legacy_client_v1XKct5FiP,
  bugsnag_tkMQfOjcVZ,
  gtm_client_OzmBuHLRIb,
  intercom_client_vr7NPky0pK
]