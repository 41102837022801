import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { type ParametersGetContractChatMessages, responseDataTypeName, useFetchContractChat } from './useFetchContractChat'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  byContractId: ({ contractId, workspaceId }: { contractId: string, workspaceId: MaybeRef<string> }) => ({
    queryKey: [contractId, workspaceId],
  }),
  contractChatMessages: ({
    contractId,
    chatId,
    workspaceId,
    params,
  }: {
    contractId: string
    chatId: string
    workspaceId: MaybeRef<string>
    params?: MaybeRef<ParametersGetContractChatMessages>
  }) => ({
    queryKey: [contractId, chatId, workspaceId, params],
  }),
})

const byContractId = ({
  contractId,
  useQueryOptions,
}: {
  contractId: string
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { byContractId } = useFetchContractChat()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.byContractId({
      contractId,
      workspaceId: activeWorkspaceId as MaybeRef<string>,
    }),
    queryFn: () => byContractId({
      contractId,
      workspaceId: unref(activeWorkspaceId) ?? '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const getMessages = ({
  contractId,
  chatId,
  params,
  useQueryOptions,
}: {
  contractId: string
  chatId: string
  params?: MaybeRef<ParametersGetContractChatMessages>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getMessages } = useFetchContractChat()
  const { activeWorkspaceId } = useAuth()

  return useInfiniteQuery({
    ...queryKeys.contractChatMessages({
      contractId,
      chatId,
      params,
      workspaceId: activeWorkspaceId as MaybeRef<string>,
    }),
    queryFn: ({ pageParam }) => getMessages({
      contractId,
      chatId,
      params: {
        ...unref(params),
        page: pageParam,
      },
      workspaceId: unref(activeWorkspaceId) ?? '',
    }),
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.meta?.pagination?.current_page
      const totalPages = lastPage.meta?.pagination?.total_pages

      if (currentPage === totalPages)
        return null

      return (currentPage ?? 0) + 1
    },
    initialPageParam: 1,
    ...useQueryOptions,
  })
}

export const useQueryContractChat = ({
  byContractId,
  getMessages,
})
