import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { useFetchPartner } from './useFetchPartner'

export const queryKeys = createQueryKeys('partner', {
  byType: ({
    workspaceId,
    type,
  }: {
    workspaceId: MaybeRef<string>
    type: MaybeRef<string>
  }) => ({
    queryKey: [
      workspaceId,
      type,
    ],
  }),
})

const byType = ({
  type,
  useQueryOptions = {},
}: {
  type: MaybeRef<string>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { byType } = useFetchPartner()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.byType({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      type,
    }),
    queryFn: () => byType({
      type: unref(type),
      workspaceId: unref(activeWorkspaceId) ?? '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryPartner = ({
  byType,
})
