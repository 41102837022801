import type { Partner } from '~/types/auth'
import type { Response } from '~/types/api/v1'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const useFetchPartner = () => {
  const { get } = useFetchWithDefaults()

  const byType = ({
    type,
    workspaceId,
  }:
    WorkspaceIdentifier & {
      type: string
    }) => get<Response<Partner>>({
    url: `/partners/byType/${type}`,
    workspaceId,
  })

  return {
    byType,
  }
}
