import { default as _91_46_46_46slug_939vZo5AM7v9Meta } from "/vercel/path0/apps/app/pages/[...slug].vue?macro=true";
import { default as _91partner_93QfxeyeeWYaMeta } from "/vercel/path0/apps/app/pages/2/activate-partnership/[partner].vue?macro=true";
import { default as cancellationya2L9Gd1hsMeta } from "/vercel/path0/apps/app/pages/2/cancellation.vue?macro=true";
import { default as _91_46_46_46slug_93KIRVZBkEjYMeta } from "/vercel/path0/apps/app/pages/2/documents/[...slug].vue?macro=true";
import { default as _91documentId_93jRygBxK9HnMeta } from "/vercel/path0/apps/app/pages/2/documents/d/[documentId].vue?macro=true";
import { default as indexJkQcFncGBSMeta } from "/vercel/path0/apps/app/pages/2/documents/d/index.vue?macro=true";
import { default as _91folderId_93DKMhzGszWFMeta } from "/vercel/path0/apps/app/pages/2/documents/f/[folderId].vue?macro=true";
import { default as index0A2LQMKCcoMeta } from "/vercel/path0/apps/app/pages/2/documents/f/index.vue?macro=true";
import { default as indexNaOueEgIFwMeta } from "/vercel/path0/apps/app/pages/2/documents/index.vue?macro=true";
import { default as _91viewId_934cvBDwJnJsMeta } from "/vercel/path0/apps/app/pages/2/documents/v/[viewId].vue?macro=true";
import { default as newYxsVZVa2ImMeta } from "/vercel/path0/apps/app/pages/2/documents/v/new.vue?macro=true";
import { default as not_45foundMNpFOdbhKQMeta } from "/vercel/path0/apps/app/pages/2/documents/v/not-found.vue?macro=true";
import { default as recentqBCdysbeC9Meta } from "/vercel/path0/apps/app/pages/2/documents/v/recent.vue?macro=true";
import { default as enable_45mfaTPpnSeVWf0Meta } from "/vercel/path0/apps/app/pages/2/enable-mfa.vue?macro=true";
import { default as home0TaeKyigHYMeta } from "/vercel/path0/apps/app/pages/2/home.vue?macro=true";
import { default as logoutnElLmgwZPeMeta } from "/vercel/path0/apps/app/pages/2/logout.vue?macro=true";
import { default as referral7zA6ePcgr6Meta } from "/vercel/path0/apps/app/pages/2/referral.vue?macro=true";
import { default as searchOx1AM2LO9NMeta } from "/vercel/path0/apps/app/pages/2/search.vue?macro=true";
import { default as documentsYxDPKsBXMYMeta } from "/vercel/path0/apps/app/pages/2/settings/documents.vue?macro=true";
import { default as userssWz66OG55lMeta } from "/vercel/path0/apps/app/pages/2/settings/users.vue?macro=true";
import { default as signW9nCjzJ5BOMeta } from "/vercel/path0/apps/app/pages/2/sign.vue?macro=true";
import { default as indexn541PX2OtZMeta } from "/vercel/path0/apps/app/pages/2/subscription/index.vue?macro=true";
import { default as upkeep3csdd9vQpmMeta } from "/vercel/path0/apps/app/pages/2/subscription/upkeep.vue?macro=true";
import { default as _91templateId_9349aEd907zyMeta } from "/vercel/path0/apps/app/pages/2/template/[templateId].vue?macro=true";
import { default as create_45_91type_93_45_91id_93tuIVyVPerZMeta } from "/vercel/path0/apps/app/pages/2/template/create-[type]-[id].vue?macro=true";
import { default as indexrp46171La5Meta } from "/vercel/path0/apps/app/pages/2/template/index.vue?macro=true";
import { default as _91userTemplateId_93mcI0i5V6GVMeta } from "/vercel/path0/apps/app/pages/2/template/own/[userTemplateId].vue?macro=true";
import { default as templateHjVm976VQqMeta } from "/vercel/path0/apps/app/pages/2/template.vue?macro=true";
import { default as alleaGnsiW4VIMeta } from "/vercel/path0/apps/app/pages/2/templates/all.vue?macro=true";
import { default as _91categoryId_93Ot4QZGtzlrMeta } from "/vercel/path0/apps/app/pages/2/templates/category/[categoryId].vue?macro=true";
import { default as _91collectionId_93MbBGWqRwS0Meta } from "/vercel/path0/apps/app/pages/2/templates/collection/[collectionId].vue?macro=true";
import { default as favourites4morT0QK1sMeta } from "/vercel/path0/apps/app/pages/2/templates/favourites.vue?macro=true";
import { default as indexGrrSEERPgyMeta } from "/vercel/path0/apps/app/pages/2/templates/index.vue?macro=true";
import { default as ownajiTuTjpN5Meta } from "/vercel/path0/apps/app/pages/2/templates/own.vue?macro=true";
import { default as recent0boweK8pNsMeta } from "/vercel/path0/apps/app/pages/2/templates/recent.vue?macro=true";
import { default as templatesLUxDDK5DcHMeta } from "/vercel/path0/apps/app/pages/2/templates.vue?macro=true";
import { default as _2eKSrPwmLnaMeta } from "/vercel/path0/apps/app/pages/2.vue?macro=true";
import { default as _91secret_93sm6UEjbZxBMeta } from "/vercel/path0/apps/app/pages/welcome/[secret].vue?macro=true";
import { default as billingqPvcmNlvpDMeta } from "/vercel/path0/apps/app/pages/welcome/billing.vue?macro=true";
import { default as indexGnFb15ve90Meta } from "/vercel/path0/apps/app/pages/welcome/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/apps/app/pages/[...slug].vue")
  },
  {
    name: "2",
    path: "/2",
    component: () => import("/vercel/path0/apps/app/pages/2.vue"),
    children: [
  {
    name: "2-activate-partnership-partner",
    path: "activate-partnership/:partner()",
    component: () => import("/vercel/path0/apps/app/pages/2/activate-partnership/[partner].vue")
  },
  {
    name: "2-cancellation",
    path: "cancellation",
    meta: cancellationya2L9Gd1hsMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/cancellation.vue")
  },
  {
    name: "2-documents-slug",
    path: "documents/:slug(.*)*",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/[...slug].vue")
  },
  {
    name: "2-documents-d-documentId",
    path: "documents/d/:documentId()",
    meta: _91documentId_93jRygBxK9HnMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/documents/d/[documentId].vue")
  },
  {
    name: "2-documents-d",
    path: "documents/d",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/d/index.vue")
  },
  {
    name: "2-documents-f-folderId",
    path: "documents/f/:folderId()",
    meta: _91folderId_93DKMhzGszWFMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/documents/f/[folderId].vue")
  },
  {
    name: "2-documents-f",
    path: "documents/f",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/f/index.vue")
  },
  {
    name: "2-documents",
    path: "documents",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/index.vue")
  },
  {
    name: "2-documents-v-viewId",
    path: "documents/v/:viewId()",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/v/[viewId].vue")
  },
  {
    name: "2-documents-v-new",
    path: "documents/v/new",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/v/new.vue")
  },
  {
    name: "2-documents-v-not-found",
    path: "documents/v/not-found",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/v/not-found.vue")
  },
  {
    name: "2-documents-v-recent",
    path: "documents/v/recent",
    component: () => import("/vercel/path0/apps/app/pages/2/documents/v/recent.vue")
  },
  {
    name: "2-enable-mfa",
    path: "enable-mfa",
    meta: enable_45mfaTPpnSeVWf0Meta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/enable-mfa.vue")
  },
  {
    name: "2-home",
    path: "home",
    meta: home0TaeKyigHYMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/home.vue")
  },
  {
    name: "2-logout",
    path: "logout",
    meta: logoutnElLmgwZPeMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/logout.vue")
  },
  {
    name: "2-referral",
    path: "referral",
    meta: referral7zA6ePcgr6Meta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/referral.vue")
  },
  {
    name: "2-search",
    path: "search",
    meta: searchOx1AM2LO9NMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/search.vue")
  },
  {
    name: "2-settings-documents",
    path: "settings/documents",
    meta: documentsYxDPKsBXMYMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/settings/documents.vue")
  },
  {
    name: "2-settings-users",
    path: "settings/users",
    meta: userssWz66OG55lMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/settings/users.vue")
  },
  {
    name: "2-sign",
    path: "sign",
    component: () => import("/vercel/path0/apps/app/pages/2/sign.vue")
  },
  {
    name: "2-subscription",
    path: "subscription",
    meta: indexn541PX2OtZMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/subscription/index.vue")
  },
  {
    name: "2-subscription-upkeep",
    path: "subscription/upkeep",
    meta: upkeep3csdd9vQpmMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/subscription/upkeep.vue")
  },
  {
    name: templateHjVm976VQqMeta?.name,
    path: "template",
    component: () => import("/vercel/path0/apps/app/pages/2/template.vue"),
    children: [
  {
    name: "2-template-templateId",
    path: ":templateId()",
    meta: _91templateId_9349aEd907zyMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/template/[templateId].vue")
  },
  {
    name: "2-template-create-type-id",
    path: "create-:type()-:id()",
    meta: create_45_91type_93_45_91id_93tuIVyVPerZMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/template/create-[type]-[id].vue")
  },
  {
    name: "2-template",
    path: "",
    meta: indexrp46171La5Meta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/template/index.vue")
  },
  {
    name: "2-template-own-userTemplateId",
    path: "own/:userTemplateId()",
    meta: _91userTemplateId_93mcI0i5V6GVMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/template/own/[userTemplateId].vue")
  }
]
  },
  {
    name: templatesLUxDDK5DcHMeta?.name,
    path: "templates",
    meta: templatesLUxDDK5DcHMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/2/templates.vue"),
    children: [
  {
    name: "2-templates-all",
    path: "all",
    component: () => import("/vercel/path0/apps/app/pages/2/templates/all.vue")
  },
  {
    name: "2-templates-category-categoryId",
    path: "category/:categoryId()",
    component: () => import("/vercel/path0/apps/app/pages/2/templates/category/[categoryId].vue")
  },
  {
    name: "2-templates-collection-collectionId",
    path: "collection/:collectionId()",
    component: () => import("/vercel/path0/apps/app/pages/2/templates/collection/[collectionId].vue")
  },
  {
    name: "2-templates-favourites",
    path: "favourites",
    component: () => import("/vercel/path0/apps/app/pages/2/templates/favourites.vue")
  },
  {
    name: "2-templates",
    path: "",
    component: () => import("/vercel/path0/apps/app/pages/2/templates/index.vue")
  },
  {
    name: "2-templates-own",
    path: "own",
    component: () => import("/vercel/path0/apps/app/pages/2/templates/own.vue")
  },
  {
    name: "2-templates-recent",
    path: "recent",
    component: () => import("/vercel/path0/apps/app/pages/2/templates/recent.vue")
  }
]
  }
]
  },
  {
    name: "welcome-secret",
    path: "/welcome/:secret()",
    meta: _91secret_93sm6UEjbZxBMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/welcome/[secret].vue")
  },
  {
    name: "welcome-billing",
    path: "/welcome/billing",
    meta: billingqPvcmNlvpDMeta || {},
    component: () => import("/vercel/path0/apps/app/pages/welcome/billing.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/vercel/path0/apps/app/pages/welcome/index.vue")
  }
]