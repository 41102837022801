import type { Response } from '~/types/api/v1'
import type { ChatMessage, ContractChat } from '~/types/chat'

import { populateRelationships } from '~/utils/api/v1'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const responseDataTypeName = 'contract-chat'

export interface ParametersGetContractChatMessages {
  limit?: number
  include?: 'chatRecipient'
}

export interface ParametersGetContractChatMessagesWithPage extends ParametersGetContractChatMessages {
  page?: number
}

export const useFetchContractChat = () => {
  const { get } = useFetchWithDefaults()

  const url = (contractId: string) => `/me/contracts/${contractId}/chats`

  const byContractId = ({
    contractId,
    workspaceId,
  }: {
    contractId: string
  } & WorkspaceIdentifier) => get<Response<ContractChat[]>>({
    workspaceId,
    url: url(contractId),
    fetchOptions: {
      params: {
        include: 'chatRecipients',
      },
    },
  }).then(populateRelationships)

  const getMessages = ({
    contractId,
    chatId,
    workspaceId,
    params,
  }: {
    contractId: string
    chatId: string
    params?: ParametersGetContractChatMessagesWithPage
  } & WorkspaceIdentifier) => get<Response<ChatMessage[]>>({
    workspaceId,
    url: `${url(contractId)}/${chatId}/messages`,
    fetchOptions: {
      params,
    },
  }).then(populateRelationships)

  return {
    byContractId,
    getMessages,
  }
}
