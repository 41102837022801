import type * as API from '~/types/api'
import type { EmployeeCountMap, ShareholderCountMap, Workspace } from '~/types/auth'

import { populateRelationships } from '~/utils/api/v1'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const responseDataTypeName: Workspace['type'] = 'company'

export interface ParametersGetAll {
  limit: number
}

export type IncludableField = 'role' | 'partner' | 'preferences'

export type UpdateWorkspacePayload = Partial<Workspace['attributes']>
export interface UpdateWorkspaceMetaPayload {
  avatar?: {
    content: string
    filename: string
  }
  logo?: {
    content: string
    filename: string
  }
  businessProfile?: {
    employee_amount?: typeof EmployeeCountMap[keyof typeof EmployeeCountMap]
    shareholder_amount?: typeof ShareholderCountMap[keyof typeof ShareholderCountMap]
    collects_personal_data?: boolean
  }
}

export const useFetchWorkspace = () => {
  const url = '/me/companies'

  const { get, put, post } = useFetchWithDefaults()

  const getAll = ({ params }: { params?: ParametersGetAll } = {}) =>
    get<API.v1.Response<Workspace[]>>({ url, fetchOptions: { params } })

  const getById = ({
    workspaceId,
    fieldsToInclude,
  }: WorkspaceIdentifier & { fieldsToInclude?: IncludableField[] }) =>
    get<API.v1.Response<Workspace>>({
      url: `${url}/${workspaceId}`,
      workspaceId,
      fetchOptions: {
        params: { include: fieldsToInclude ?? [] },
      },
    }).then(populateRelationships)

  const update = ({
    workspaceId,
    payload,
    meta,
  }: WorkspaceIdentifier & { payload: UpdateWorkspacePayload, meta?: UpdateWorkspaceMetaPayload }) => put<Workspace>({
    url: `${url}/${workspaceId}`,
    workspaceId,
    fetchOptions: {
      body: {
        data: {
          id: workspaceId,
          type: 'company',
          attributes: payload,
          ...(meta ? { meta } : {}),
        },
      },
    },
  })

  const activatePartnership = ({
    workspaceId,
    payload,
  }: WorkspaceIdentifier & {
    payload: {
      partner: string
      code: string
    }
  }) => post({
    url: '/v2/workspaces/activate-partnership',
    workspaceId,
    fetchOptions: {
      body: payload,
    },
  })

  return {
    getAll,
    getById,
    update,
    activatePartnership,
  }
}
