import { useFetchWorkspace } from '~/composables/api/workspace/useFetchWorkspace'

export default defineNuxtRouteMiddleware(async (to) => {
  const { user, apiToken, activeWorkspaceId, fetchActiveWorkspaceData } = useAuth()
  const { language } = useLanguage()

  useActiveWorkspaceStorageListener()

  const { public: { appV1Url } } = useRuntimeConfig()

  const currentUrl = new URL(location.href)
  const isChildApp = currentUrl.searchParams.has('is-child-app')

  const loginUrl = new URL('login', appV1Url)
  const createWorkspaceUrl = new URL('signup/create-workspace?fallback=true', appV1Url)

  if (!isChildApp) {
    loginUrl.searchParams.set('return', location.href)
    createWorkspaceUrl.searchParams.set('return', location.href)
  }

  const loginPath = loginUrl.toString()
  const createWorkspacePath = createWorkspaceUrl.toString()

  if (apiToken.value && activeWorkspaceId.value) {
    try {
      user.value ??= await useApi().me().then(r => r.data)
      language.value = user.value!.attributes.language!

      if (activeWorkspaceId.value === 'personal') {
        // TODO DEV-5797 refactor this file
        const { data: workspaces } = await useFetchWorkspace().getAll({ params: { limit: 1 } })
        if (!workspaces.length && !to.meta.public)
          return navigateTo(createWorkspacePath, { external: true })

        return
      }
      else {
        await fetchActiveWorkspaceData({ refresh: false })
      }

      return
    }
    catch (e) {
      console.error(e)
      // Clean token if request fails
      apiToken.value = null
    }
  }

  if (to.meta.public)
    return

  await navigateTo(loginPath, { external: true })
  return abortNavigation()
})
